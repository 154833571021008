:root {
    --font-montserrat: "Montserrat", sans-serif;
    --font-open-sans: "Open Sans", sans-serif;
    --text-color-purple: #480e44;
}

body {
    font-family: var(--font-open-sans);
    font-size: 14px;
    color: var(--text-color-purple);
    line-height: 24px;

    &.body-theme {
        background-image: url("/data/images/shutterstock_748701829.jpg");
        /* TODO: Dit image mag NIET in /data/ staan, moet in /static/ */
    }

    &.riec-om {
        background-image: url("/data/images/img-riec-om.jpg");
        /* TODO: Dit image mag NIET in /data/ staan, moet in /static/ */
    }

    &.noscroll {
        overflow: hidden;
    }
}

.Montserrat {
    font-family: var(--font-montserrat);
}

.OpenSans {
    font-family: var(--font-open-sans);
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("../fonts/fa-solid-900.eot");
    src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
        url("../fonts/fa-solid-900.woff2") format("woff2"),
        url("../fonts/fa-solid-900.woff") format("woff"),
        url("../fonts/fa-solid-900.ttf") format("truetype"),
        url("../fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.F_Size {
    &-16 {
        font-size: 16px;
    }

    &-17 {
        font-size: 16px;
    }
}

.font-weight {

    //Light
    &-300 {
        font-weight: 300;
    }

    //Medium
    &-500 {
        font-weight: 500;
    }

    //Semi-bold
    &-600 {
        font-weight: 600;
    }

    //Extra-bold
    &-800 {
        font-weight: 800;
    }

    //Black
    &-900 {
        font-weight: 900;
    }
}

h1 {
    font-size: 58px;
    font-family: var(--font-montserrat);
    line-height: normal;
}

h2 {
    font-family: var(--font-montserrat);
    line-height: normal;
}

h3 {
    font-family: var(--font-montserrat);
    line-height: normal;
    font-weight: 800;
    font-size: 32px;
    color: #480e44;
}

h5 {
    font-family: var(--font-montserrat);
    line-height: normal;
    font-size: 24px;
}

h6 {
    font-family: var(--font-montserrat);
    line-height: normal;
    font-size: 20px;
}

a {
    color: #480e44;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

.ColorWhite {
    color: #fff;
}

.close-icon {
    color: $primary;
}

.breadcrumbs {

    /*padding-top: 41px;*/
    .breadcrumb {
        position: relative;
        z-index: 1;

        .breadcrumb-item {
            color: #fff;

            &.active,
            a {
                color: #fff;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.BtnPurple {
    background-color: $riec-purple;
    border-color: $riec-purple;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    border-radius: 8px;

    &:hover {
        background-color: transparent;
        color: $riec-purple;
    }

    .arrow {
        // font-size: 12px;
    }
}

.BtnWhite {
    background-color: #fff;
    border-color: #fff;
    font-size: 20px;
    color: $riec-purple;
    border-radius: 8px;

    &:hover {
        background-color: transparent;
        color: #fff;
    }
}

.BtnBlue {
    border-radius: 6px;
    background-color: #00929f;
    border-color: #00929f;
    font-size: 16px;
    color: #fff;

    &:hover {
        background-color: transparent;
        color: #00929f;
    }
}

.BtnPurplSmall {
    border-radius: 6px;
    background-color: $riec-purple;
    border-color: $riec-purple;
    font-size: 16px;
    color: #fff;

    &:hover {
        background-color: transparent;
        color: $riec-purple;
    }
}

.scrollbtn {
    padding: 1rem 0;
    color: $secondary;

    a {
        color: $secondary;
        font-weight: 800;
    }
}

.scrollwhite {
    padding: 1rem 0;

    image {
        color: $secondary;
    }

    a {
        color: $secondary;
        font-weight: 800;
    }
}

.scrollpink {
    padding: 1rem 0;

    a {
        color: $info;
        font-weight: 800;
    }
}

.scrollblue {
    padding: 1rem 0;

    a {
        color: $light;
        font-weight: 800;
    }
}

.more {
    display: none;
}

.morelink {
    background: url("/site/static/images/plus-circle-solid-purple.svg") no-repeat right center;
    width: 110px;
    padding-right: 35px;
    line-height: 24px;
    display: inline-block;

    &.expanded {
        background: url("/site/static/images/minus-circle-solid-purple.svg") no-repeat right center;
        width: 130px;
    }
}

#BulletNav {
    background-color: #480e44;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 36px;
    position: fixed;
    right: 0;
    top: 100px;
    padding: 10px 0;
    z-index: 5;

    a {
        display: block;
        margin: 6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);

        &.active,
        &:hover {
            background-color: rgba(255, 255, 255, 1);
        }
    }
}

.HeightBox {
    height: 300px;

    img,
    iframe {
        object-fit: cover;
    }
}

.home-header-background {
    // background-image: url("/data/images/Laag-1.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    position: relative;
    color: #fff;

    &:before {
        z-index: 0;
        content: "";
        @include absolute(0, 0, 0, 0);
        background: linear-gradient(180deg,
                rgba(0, 0, 0, 1) 0%,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 212, 255, 0) 50%);
    }

    .TextBox {
        font-size: 16px;

        h1 {
            font-size: 58px;
        }
    }

    .arrow-down {
        color: $secondary;
    }

    .header-section {
        position: relative;
        z-index: 1;

        .ImageOverLap {
            margin-top: -80px;
            position: relative;
            z-index: 2;
        }
    }

    .thema-overzicht {
        background-image: linear-gradient(#e2eef0, #e2eef0);
        background-size: 100% 70%;
        background-position-y: 100%;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        color: #480e44;

        /*background visible for acoording bootstrap 5 Extra small (.col-) */
        @media only screen and (max-width: 576px) {
            background-size: 100% 78%;
            background-position-y: 100%;
        }

        /*background visible for acoording bootstrap 5 Small (.col-sm-) */
        @media only screen and (min-width: 576px) {
            background-size: 100% 87%;
            background-position-y: 100%;
        }

        /*background visible for acoording bootstrap 5 Medium (.col-md-) */
        @media only screen and (min-width: 768px) {
            background-size: 100% 79%;
            background-position-y: 100%;
        }

        /*background visible for acoording bootstrap 5 Large (.col-lg-) */
        @media only screen and (min-width: 992px) {
            background-size: 100% 70%;
            background-position-y: 100%;
        }

        /*background visible for acoording bootstrap 5 Eaxtra large (.col-xl-) */
        @media only screen and (min-width: 1200px) {
            background-size: 100% 68%;
            background-position-y: 100%;
        }

        h3 {
            font-size: 36px;
            color: #fff;
        }

        .arrow-down {
            color: $secondary;
        }

        .thema-overzicht-boxes {
            h5 {
                color: #480e44;
                font-size: 24px;
                min-height: 60px;
            }

            img {
                object-fit: contain;
                height: 100px;
            }
        }
    }
}

.home-samenwerken {
    background-color: #fff;

    .TextBox {
        min-height: 200px;
    }

    .arrow-down {
        color: $light;
    }

    .arrow-more {
        padding-left: 0.5rem;
    }

    .title {
        font-weight: 600;
    }

    .link {
        font-weight: 400;
    }
}

.home-over-ons {
    background-color: #f5eff5;

    .svg-container {
        height: 200px;
        display: grid;
        justify-content: center;
        align-content: center;
    }

    .arrow-more {
        padding-left: 0.5rem;
    }

    .button-over-ons {
        background-color: #00929f;
        border: none;
    }

    .arrow-down {
        color: $secondary;
    }

    p {
        font-weight: 400;
    }
}

.inverstering2021 {
    background-color: #fff;

    .LightText {
        font-size: 48px;
        font-weight: 300;
        color: $riec-purple;
        line-height: normal;
    }

    .arrow-down {
        color: $info;
    }

    .arrow-more {
        padding-left: 0.5rem;
    }
}

footer {
    background-color: #480e44;
    height: 50px;
}

.thema-header-backgroundimage {
    background-image: url("/data/images/Laag-1.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;

    &:before {
        z-index: 0;
        content: "";
        @include absolute(0, 0, 0, 0);
        background: linear-gradient(180deg,
                rgba(0, 0, 0, 1) 0%,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 212, 255, 0) 50%);
    }

    .thema-header-background {
        position: relative;
        z-index: 1;

        .header-section {
            h1 {
                color: $secondary;
            }
        }

        .pink {
            background-image: linear-gradient(#f5eff5, #f5eff5);
            background-size: 100% 73%;
            background-repeat: no-repeat;
            background-position-y: 100%;

            /*background visible for acoording bootstrap 5 Extra small (.col-) */
            @media only screen and (max-width: 576px) {
                background-size: 100% 60%;
                background-position-y: 100%;
            }

            .theme-container {
                .theme-boxes {
                    color: #480e44;

                    .theme-text {
                        .read-more-link {
                            font-weight: 700;
                        }
                    }

                    .theme-header {
                        height: 150px;
                        display: grid;
                        align-content: space-between;
                        justify-content: center;

                        .title-boxes {
                            padding-top: 10px;
                        }

                        .button-boxes {
                            display: grid;
                            margin-bottom: 10px;
                            width: 100px;
                            justify-self: center;
                            background-color: #00929f;
                        }
                    }
                }

                .button-alle-projecten {
                    padding-top: 2rem;

                    .button-styling {
                        background-color: $riec-purple;
                        color: #fff;
                    }
                }

                .arrow-down {
                    color: $secondary;
                }
            }
        }

        .blue {
            background-image: linear-gradient(#e2eef0, #e2eef0);
            background-size: 100% 73%;
            background-repeat: no-repeat;
            background-position-y: 100%;

            @media only screen and (max-width: 576px) {
                background-size: 100% 60%;
                background-position-y: 100%;
            }

            .theme-container {
                .titel-theme {
                    padding-bottom: 1rem;

                    h3 {
                        color: #fff;
                        font-weight: 800;
                    }
                }

                .theme-boxes {
                    color: #480e44;

                    .theme-header {
                        height: 150px;
                        display: grid;
                        align-content: space-between;
                        justify-content: center;

                        .title-boxes {
                            padding-top: 10px;
                        }

                        .button-boxes {
                            display: grid;
                            margin-bottom: 10px;
                            width: 100px;
                            justify-self: center;
                            background-color: #00929f;
                        }
                    }

                    .theme-text {
                        padding: 30px;

                        a {
                            color: #480e44;
                            font-weight: 600;
                        }

                        .pager {
                            display: flex;

                            ul {
                                display: flex;
                                width: 10%;
                                justify-content: space-between;
                                align-items: flex-end;
                                padding-left: 0;

                                li {
                                    display: block;

                                    a {
                                        font-size: 1.3rem;
                                    }
                                }
                            }

                            .is-current-page {
                                color: $success;
                            }
                        }
                    }
                }

                .button-alle-projecten {
                    padding-top: 2rem;

                    .button-styling {
                        background-color: $riec-purple;
                        color: #fff;
                    }
                }
            }

            .nh-structuur-container {
                .blue .arrow-down {
                    color: $secondary;
                }
            }
        }

        .orangebackground {
            background-color: #e3a51a;
        }

        .orange {
            background-image: linear-gradient(#e3a51a, #e3a51a);
            background-size: 100% 73%;
            background-repeat: no-repeat;
            background-position-y: 100%;

            @media only screen and (max-width: 576px) {
                background-size: 100% 60%;
                background-position-y: 100%;
            }

            .theme-container {
                // padding-left: 0;

                .titel-theme {
                    padding-bottom: 1rem;

                    h3 {
                        color: #fff;
                        font-weight: 800;
                    }
                }

                .theme-boxes {
                    color: #480e44;

                    .theme-header {
                        height: 150px;
                        display: grid;
                        align-content: space-between;
                        justify-content: center;

                        .title-boxes {
                            padding-top: 10px;
                        }

                        .button-boxes {
                            display: grid;
                            margin-bottom: 10px;
                            width: 100px;
                            justify-self: center;
                            background-color: #00929f;
                        }
                    }

                    .theme-text {
                        padding: 30px;

                        a {
                            color: #480e44;
                            font-weight: 600;
                        }
                    }
                }

                .button-alle-projecten {
                    padding-top: 2rem;

                    .button-styling {
                        background-color: $riec-purple;
                        color: #fff;
                    }
                }
            }
        }

        .theme-container {
            .theme-boxes {
                .theme-text {
                    padding: 1rem;
                }

                .read-more-link {
                    color: $primary;
                }
            }

            .arrow-down {
                color: $secondary;
            }
        }
    }
}


#EnforcementBottles {
    background-color: #e3a51a;

    ul {
        li {
            line-height: 19px;
            align-items: center;
            padding-right: 20px;

            .knel-circle {
                background-color: white;
                border-radius: 50%;
                height: 48px;
                width: 48px;
                margin-right: 12px;

                svg {
                    height: 48px;
                    width: 48px;
                }
            }

            img,
            .knel-circle {
                width: 48px;
                height: 48px;
                object-fit: cover;
                border-radius: 50%;
            }

            .item-text {
                padding-left: 1rem;
            }
        }
    }

    .arrow-down {
        color: $secondary;
    }
}

.enforcement-logo {
    width: 194px;
    height: 194px;
    padding: 20px;
    margin: 40px;
}

.thema-deelprojecten {
    background-color: #e2eef0;

    .svg-container {
        height: 200px;
        display: grid;
        justify-content: center;
        align-content: center;
    }

    .button-over-ons {
        background-color: #00929f;
        border: none;
    }
}

.thema-deelprojecten.orange {
    background-color: #e3a51a;
}

.thema-versterken-onderzoek {
    background-color: #fff;
    color: #480e44;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    .title {
        margin: 1rem 0;

        h3 {
            font-size: 1.5rem;
            font-weight: 400;
        }
    }

    h5 {
        color: #00929f;
        font-weight: 400;
    }

    .subtitle {
        padding-bottom: 0.75rem;
    }

    a {
        color: #480e44;
    }

    .deelproject-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;

        .close-item {
            display: flex;
            font-size: 1.5rem;
            padding: 1rem 0;
        }

        h1 {
            font-size: 2rem;
            font-weight: 600;
            padding: 1rem 0;
            display: flex;
        }
    }

    .doelen-resultaten-container {
        padding-bottom: 1rem;

        .doelen-resultaten-content {
            background-color: $success;
            height: 100%;
            color: #fff;
            padding: 0;

            .item-container {
                padding: 1rem 0;

                div {
                    // display: flex;
                    // justify-self: flex-start;
                    margin: 0;
                }

                .image-container {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    img {
                        display: flex;
                        justify-content: flex-end;
                        align-content: center;
                    }
                }
            }

            .title-container {
                padding: 1rem 0;

                h5 {
                    color: #fff;
                }
            }

            ul {
                li {
                    padding: 0.5rem 0.25rem;
                }
            }

            .item-container {
                .colorbox-content {
                    ul {
                        list-style-type: none;
                        padding-left: 0;

                        li {
                            padding: 0;
                            padding-left: 2rem;
                            margin-bottom: 0.4rem;
                            background-image: url(/static/images/check-solid-purple.svg);
                            background-size: 15px 15px;
                            background-position: 0% 0.7rem;
                            background-repeat: no-repeat;
                            padding-top: 0.5rem;
                            // line-height: 1.5rem;
                            // margin-bottom: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .linkjes-downloads-container {
        .items-container {
            display: flex;
            align-items: center;
            // border: none;

            p {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0;
            }

            img {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            i {
                color: $dark;
                padding-right: 0.5rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                font-size: 1.25rem;
            }
        }

        .items-container-subproject {
            display: flex;
            align-items: center;
            // border: none;

            p {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0;
            }

            img {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            i {
                color: $dark;
                padding-right: 0.5rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                font-size: 1.25rem;
            }
        }

        ul {
            li {
                padding: 0.5rem 0.25rem;
                display: flex;
            }
        }
    }

    .sluit-container {
        font-size: 1rem;
        margin-bottom: 2rem;

        a {
            font-size: 0.85rem;
            padding-bottom: 0;
        }

        .close-icon {
            font-size: 1rem;
            padding-left: 0.5rem;
        }
    }

    .deelproject-image {
        width: 100%;
    }
}

.thema-overzicht-projecten {
    background-color: #e2eef0;

    .close-item-row {
        font-size: 1.2rem;
    }

    .arrow-container {
        background-color: red;
    }

    .project-item-container {
        background-color: $secondary;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: 10px 0;

        a {
            color: #480e44;
        }
    }

    .arrow-down {
        color: $secondary;
    }

    .subtitle-handhaving {
        font-size: 1.5rem;
        font-weight: 400;
    }
}

.thema-overzicht-projecten.orange {
    background-color: #e3a51a;
}

.thema-overige-projecten {
    background-color: #e2eef0;
    padding-bottom: 2rem;

    .tumbnail-image {
        background-size: cover;
        display: block;
        margin-top: 2rem;

        .thumbnail-title-container {
            background-color: #fff;
            height: 3rem;

            .title {
                color: #00929f;
                font-weight: 600;
            }

            .title-bruin {
                color: #ad8116;
                font-weight: 600;
            }
        }

        .thumbnail-text-container {
            background-color: rgba(0, 0, 0, 0.6);
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            h5 {
                color: #fff;
            }
        }
    }

    .gefinacierd-door-container {
        margin: 1rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        p {
            font-size: 12px;
            margin-bottom: 0;
            margin-left: 1rem;
        }
    }
}

.diensten-producten {
    background-color: $danger;
    color: $primary;

    h3 {
        display: block;
        padding: 1rem 0;
    }

    .IconCircle {
        background-color: #480e44;
        width: 120px;
        height: 120px;
        border-radius: 50%;

        .st0 {
            fill: #e3a51a !important;
        }
    }

    .arrow-down {
        color: $secondary;
    }
}

.thema-programma-aanpak {
    background-color: $secondary;
    color: $primary;
    padding: 2rem 0;
}

.thema-ondermijning-sub {
    background-color: $info;
    padding: 2rem 0;

    .title {
        padding-top: 1rem;
    }

    .intro-text {
        padding: 1rem 0;
    }

    .project-item-container {
        background-color: $secondary;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: 10px 0;

        a {
            color: #480e44;
        }
    }

    .button-container {
        padding: 2rem 0;
    }

    .arrow-down {
        color: $secondary;
    }
}

.thema-ondermijning-sub-invest {
    background-color: $secondary;

    .title {
        padding-top: 2rem;
    }

    .subtitle {
        padding-top: 1rem;
    }

    .button-container {
        padding: 1rem 0;
    }

    h1 {
        color: $success;
        font-weight: 100;
        font-size: 3rem;
    }
}

/* styling voor over pagina */
#over-subheader-color {
    background: linear-gradient(#f5eff5, #f5eff5);
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position-y: 100%;
}

.paarse-bolletjes-container {
    background-color: #f5eff5;
    color: #480e44;
    padding: 1rem 0;

    .title {
        padding: 1rem 0;
    }

    a {
        color: #fff;
    }

    .paarse-bol {
        padding: 1rem 0;

        .circle {
            margin: 0 auto;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $riec-purple;

            img {
                max-width: 80px;
                max-height: 80px;
            }
        }

        .title-paarse-bol {
            padding-top: 1rem;

            h6 {
                font-size: 16px;
            }
        }
    }

    // .content-container-lists {
    // 	ul {
    // 		list-style-type: none;
    // 		padding-left: 0;

    // 		li {
    // 			padding: 0;
    // 			padding-left: 2rem;
    // 			background-image: url(/static/images/check-solid-purple.svg);
    // 			background-size: 15px 15px;
    // 			background-position: 0% 0.3rem;
    // 			background-repeat: no-repeat;
    // 		}
    // 	}
    // }

    .arrow-down {
        color: $secondary;
    }
}

.kerntaken-container {
    background-color: #fff;
    padding-top: 2rem;
    padding-bottom: 1rem;

    a {
        color: #f5eff5;
    }

    .subtitle {
        h5 {
            color: $riec-purple;
            font-weight: 600;
            margin-bottom: 22px;
            margin-top: 20px;
        }
    }

    .text {
        padding-bottom: 1rem;

        p {
            color: #480e44;
        }
    }

    .arrow-down {
        color: $info;
    }
}

.samenwerkende-container {
    background-color: #e2eef0;

    .arrow-plus {
        padding-left: 0.5rem;
    }
}

.regio-container {
    background-color: #f5eff5;

    p {
        color: #480e44;
    }

    .item-container {
        display: flex;

        img {
            display: flex;
            justify-content: flex-start;
        }

        p {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 0;
        }
    }

    .uitleg {
        padding: 1rem 0;
    }

    .landkaart {
        height: 400px;
    }

    .title {
        padding: 2rem 0;
    }

    .aanwijzing-text {
        text-align: flex-end;
        position: relative;
        padding-top: -70%;
    }

    .arrow-down {
        color: $secondary;
        font-size: 2rem;
        padding: 1rem 0;
    }
}

.partners-container {
    background-color: #fff;
    padding: 2rem 0;
    color: #480e44;

    .content {
        padding-top: 1rem;
    }

    .right-column-container {
        padding: 2rem;
    }

    .items-container {
        display: flex;
        padding: 0.1rem 0;

        img {
            display: flex;
            justify-content: flex-start;
        }

        p {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 0;
        }
    }

    .arrow-down {
        color: $info;
        font-size: 2rem;
        padding: 1rem 0;
    }
}

.structuur-container {
    background-color: $info;
    color: $primary;
    padding: 1rem 0;


    .title-container {
        padding: 1rem 0;
    }

    .content-container {
        background-color: $secondary;
        padding: 0rem 1rem;
        margin: 2rem 0;

        .content-container-lists {
            margin: 2rem 0;
        }

        h5,
        h4,
        h6 {
            color: $success;
            font-weight: 600;
            // padding-bottom: 1rem;
            font-family: "Montserrat";
        }

        h6,h5 {
            font-weight: 700;
            font-size: 1.1rem;
            color: $primary;
            margin-top: 2rem;
        }

        ul {
            margin-top: 1rem;
        }

        p {
            margin-top: 1rem;
        }

        .items-container {
            display: flex;
            /*padding: 0.5rem 0;*/
            border: none;

            img {
                display: flex;
                justify-content: flex-start;
            }

            p {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 0;
            }
        }
    }
}

.over-samen-sterk {
    background-color: $info;
    color: $primary;

    padding-top: 1rem;

    .title-tekst-container {
        padding: 1rem 0;

        h3 {
            display: block;
            padding: 1rem 0;
        }
    }

    .split-background {
        background: linear-gradient(to top,
                $secondary 0%,
                $secondary 74%,
                $secondary 50%,
                $info 50%,
                $info 100%);

        /*background visible for acoording bootstrap 5 Extra small (.col-) */
        @media only screen and (max-width: 576px) {
            background: linear-gradient(to top,
                    $secondary 0%,
                    $secondary 90%,
                    $secondary 50%,
                    $info 50%,
                    $info 100%);
        }

        /*background visible for acoording bootstrap 5 Small (.col-sm-) */
        @media only screen and (min-width: 576px) {
            background: linear-gradient(to top,
                    $secondary 0%,
                    $secondary 85%,
                    $secondary 50%,
                    $info 50%,
                    $info 100%);
        }

        /*background visible for acoording bootstrap 5 Medium (.col-md-) */
        @media only screen and (min-width: 768px) {
            background: linear-gradient(to top,
                    $secondary 0%,
                    $secondary 85%,
                    $secondary 50%,
                    $info 50%,
                    $info 100%);
        }

        /*background visible for acoording bootstrap 5 Large (.col-lg-) */
        @media only screen and (min-width: 992px) {
            background: linear-gradient(to top,
                    $secondary 0%,
                    $secondary 70%,
                    $secondary 50%,
                    $info 50%,
                    $info 100%);
        }

        /*background visible for acoording bootstrap 5 Eaxtra large (.col-xl-) */
        @media only screen and (min-width: 1200px) {
            background: linear-gradient(to top,
                    $secondary 0%,
                    $secondary 70%,
                    $secondary 50%,
                    $info 50%,
                    $info 100%);
        }

        .hand-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
        }

        img {
            margin: 1rem 0;
        }
    }

    .label-content-container {
        background-color: $secondary;

        h3 {
            display: block;
            padding: 1rem 0;
        }

        .content-in-columns {
            background-color: $info;
            height: 100%;
            padding: 1rem;

            h5 {
                text-align: center;
                display: block;
                padding: 1rem;
            }

            .item-container {
                display: flex;

                img {
                    display: flex;
                    justify-content: flex-start;
                }

                p {
                    // display: flex;
                    justify-content: flex-start;
                    margin-bottom: 0;

                    span {
                        font-weight: 800;
                    }
                }
            }
        }

        .volgende-pijler-link {
            a {
                display: block;
                padding: 2rem 0;
                color: $primary;
            }
        }

        a {
            display: block;
            color: $info;
            padding-bottom: 1rem;
        }
    }
}

.handhavingsknelpunten-container {
    background-color: $danger;
    padding: 2rem 0;
    color: $secondary;

    h3 {
        color: $secondary;
        display: block;
        padding-bottom: 2rem;
    }

    .lists-container {
        .list-column {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
                display: flex;
                margin-bottom: 0;
                color: $primary;
            }

            img {
                padding-top: 1rem;
                padding-bottom: 1rem;

                @media only screen and (max-width: 576px) {
                    margin-left: 20%;
                }
            }
        }
    }

    ul li {
        list-style-image: url(/site/static/images/style-images/white-dot.svg);
        padding: 1rem 0;
        color: $primary;
    }

    a {
        color: $secondary;
    }
}

.nh-structuur-container-two {
    .white {
        background-color: $secondary;
        padding: 2rem 0;
        color: $primary;
    }

    .arrow-down {
        color: $light;
        font-size: 2rem;
    }
}

/* styling overzicht pagina */
.sub-navigation {
    background-color: $secondary;

    .navigation-container {

        a,
        button {
            color: #00626a;

            &:hover,
            &.FilterActive {
                color: #e3a51a;
            }
        }

        .BeforeBorder {
            &:before {
                border-left: 1px solid $light;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                height: 100%;
            }

            &.last {
                border-right: 1px solid $light;
            }
        }

        .vertical-line {
            border-left: 1px solid $light;
        }
    }

    .filters {
        .IconBox {
            button {
                background-color: #e2eef0;
                width: 53px;
                height: 53px;

                &:hover,
                &.FilterActive {
                    background-color: #e3a51a;

                    svg path,
                    svg circle {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

.overzicht-projecten {
    background-color: #e2eef0;
    padding-bottom: 2rem;
    min-height: 400px;

    .tumbnail-image {
        background-size: cover;
        display: block;
        margin-top: 2rem;

        .thumbnail-title-container {
            background-color: #fff;
            height: 3rem;

            .title {
                color: #00929f;
                font-weight: 600;
            }

            .titleoption-1 {
                color: $success;
            }

            .titleoption-2 {
                color: $dark;
            }

            .titleoption-3 {
                color: $warning;
            }
        }

        .thumbnail-overlay {
            background-color: rgba(0, 0, 0, 0.6);
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            .option-1 {
                background-color: rgba(128, 33, 126, 0.8);
            }

            .option-2 {
                background-color: rgba(0, 146, 159, 0.8);
            }

            .option-3 {
                background-color: rgba(173, 129, 22, 0.8);
            }

            .color-with-text {
                width: 90%;
                height: 80%;

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* number of lines to show */
                    -webkit-box-orient: vertical;
                }

                h5 {
                    color: $secondary;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .gefinacierd-door-container {
        margin: 1rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        p {
            font-size: 12px;
            margin-bottom: 0;
            margin-left: 1rem;
        }
    }
}

/* budget page styling */
.budget-block-1 {
    background-color: $secondary;
    padding: 3rem 0;

    a {
        display: flex;
        justify-content: center;
    }

    .table-title {
        display: block;
        margin-bottom: 0;
        padding: 0.35rem 0 0.5rem 0;
        color: $secondary;
        font-size: 20px;
    }

    .purple {
        color: $success;
    }

    .purple-table-title {
        background-color: $success;
    }

    .purple-table-content {
        background-color: $info;
        margin: 1rem 0;
        padding: 0 1rem;

        .items-container {
            display: flex;
            justify-content: space-between;

            p {
                display: flex;
                margin-bottom: 0;
            }

            hr {
                border-top: 1px dashed $primary;
            }
        }
    }

    .blue {
        color: $dark;
    }

    .blue-table-title {
        background-color: $dark;
    }

    .blue-table-content {
        background-color: $light;
        margin: 1rem 0;
        padding: 0 1rem;

        .items-container {
            display: flex;
            justify-content: space-between;

            p {
                display: flex;
                margin-bottom: 0;
            }

            hr {
                border-top: 1px dashed $primary;
            }
        }
    }

    .arrow-blue {
        color: $light;
        font-size: 2rem;
        padding: 1rem 0;
    }

    .arrow-pink {
        color: #480e44;
        font-size: 2rem;
        padding: 1rem 0;
    }
}

/* styling thema/ondermijning on templatepage: program_om.html */
.thema-ondermijning-container {
    background-color: $info;
    padding-bottom: 3rem;

    .title-container {
        padding: 2rem 0;
    }

    .introduction-tekst {
        padding: 2rem 0;
    }

    .project-item {
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        height: 10rem;
        margin-bottom: 1rem;

        .project-header {
            background-color: $secondary;
            display: flex;
            justify-content: center;
            width: 100%;

            h5 {
                display: block;
                margin-bottom: 0;
                padding: 0.5rem 0;
                font-weight: 800;
                color: $success;
                font-size: 1.25rem;
            }
        }

        .project-title {
            display: flex;
            background-color: rgba($color: #000000, $alpha: 0.5);
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            h6 {
                display: flex;
                margin-bottom: 0;
                padding: 0 0.75rem;
                color: $secondary;
                font-weight: 700;
                font-size: 22px;
            }
        }
    }

    .Note-explanation {
        padding-bottom: 1rem;
    }

    .button-container {
        padding-top: 1rem;
    }

    .link {
        padding: 1rem 0;
    }
}

.iconblock {

    path,
    circle {
        fill: $dark;
    }
}

.fitsvgcontainer {
    svg {
        padding: 1rem;
        max-height: 100%;
    }
}

/* My Css */
.lasten {
    background-color: #edf4f5;
}

.totaal {
    line-height: normal;
    font-size: 48px;
    color: #00929f;
}

.LightBoxColor {
    color: #00929f;
}

.PurpleBoxColor {
    color: #480e44;

    .arrow-down {
        color: $info;
    }
}

.LightPurpleColor {
    color: $riec-purple;
}

.prognose {
    background-color: #f5eff5;
}

.linkitem {
    display: flex;
    align-items: flex-start;

    &::before {
        font: normal normal normal 14px/1 FontAwesome;
        color: $dark;
        font-size: 1.5rem;
    }

    &.documentlink::before {
        content: "\f15b";
    }

    &.linklink::before {
        content: "\f0c1";
    }

    &.linkintern::before {
        content: "\f061";
    }

    span {
        padding-left: 2rem;
    }
}

.items-container {
    border-top: dashed 1px #480e44;

    &:last-child {
        border-bottom: dashed 1px #480e44;
    }

    .linkitem::before {
        color: $success;
    }
}

.thema_normal_om_page {
    .linkitem::before {
        color: $success;
    }

    .doelen-resultaten-content {
        &.blue {
            background-color: $success;
        }

        background-color: $success;
    }

    .title {
        h3 {
            color: $success;
        }
    }

    .thema-overzicht-projecten {
        background-color: $info;
    }

    .arrow-container {
        background-color: $info;

        .arrow-down {
            color: $secondary;
            font-size: 2rem;
        }
    }
}

.thema_page {
    .linkitem::before {
        color: $dark;
    }

    .doelen-resultaten-content {
        color: $dark;

        &.blue {
            background-color: $dark;
        }
    }

    .title {
        h3 {
            color: $dark;
        }
    }

    .arrow-container {
        background-color: $light;

    }
}

.overview_page {
    .linkitem::before {
        color: $dark;
    }

    .doelen-resultaten-content {
        color: $dark;

        &.blue {
            background-color: $dark;
        }
    }
}

.ThemaLogo {
    width: 194px;
    height: 194px;
    padding: 20px;

    &:before {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #80217e;
        content: "";
        @include absolute(0, inherit, inherit, -48px);
    }

    &:after {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #80217e;
        content: "";
        @include absolute(inherit, inherit, 0, -11px);
    }

    .IconRight {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #e3a51a;
        content: "";
        @include absolute(inherit, -38px, 20px, inherit);
    }

    /*min-height: 160px;
	.position-absolute {
		width: 285px;
		top: -40px;
		right: -20px;
		svg {
			width: 100%;
		}
	}*/
}

.project-expander {
    display: none;
}

/* adjustment styling for li items instead of bullet points */
.content-container-lists {

    a {
        text-decoration: underline;
    }

    p {
        line-height: 1.8rem;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            padding: 0 0 0 2rem;
            background-image: url(/static/images/check-solid-purple.svg);
            background-size: 15px 15px;
            background-position: 0% 0.3rem;
            background-repeat: no-repeat;
            line-height: 1.8rem;
        }

        li.file {
            background-image: url(/static/images/file.svg);
        }
    }
}

.popup-container {
    .popup-dimensions {
        max-width: 90%;
    }

    h3 {
        padding-left: 4rem;
        padding-top: 2rem;
    }

    .column-left-content {
        padding-left: 4rem;
    }

    .column-right-content {
        padding-right: 4rem;
    }

    .close-icon {
        color: $primary;
        padding-right: 4rem;
        padding-top: 2rem;
    }
}

@media only screen and (min-width: 768px) {
    .two-col {
        column-count: 2;
        column-gap: $grid-gutter-width;
    }
}

.thema-overzicht-boxes {
    div {
        @media only screen and (max-width: 576px) {
            .iconblock {
                display: block !important;
            }

            h5 {
                min-height: 0 !important;
                text-align: left !important;
                margin-bottom: 0 !important;
            }

            .ratio {
                display: none;
            }

            a {
                position: relative;

                .btn {
                    border-radius: 0;
                    position: absolute;
                    top: 0.8rem;
                    right: 0rem;
                    overflow: hidden;
                    height: 2rem;
                    width: 2rem;
                    color: #00929f;
                }

                .btn::before {
                    content: "\f105";
                    color: white;
                    margin-left: -0rem;
                    font-size: 2rem;
                    position: absolute;
                    margin-top: -1rem;
                    font-family: fontawesome;
                }
            }

            p {
                display: none;
            }
        }
    }
}

.icon-circle {
    width: 10rem;
    height: 10rem;
    background-color: white;
    border-radius: 50%;
}

/* activity overlay */

#popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin: 0;
    z-index: 9000;
    overflow-y: scroll;
    display: none;

    .header {
        background-color: green;
        padding: 0;
        padding-left: 1rem;
    }

    iframe {
        z-index: 9000;
        flex-grow: 1;
        background-color: black;
    }
}

.noscroll {
    #popup {
        display: flex;
        flex-direction: column;
    }
}






/* theme color for background navigation and title bar */
.bg-light-blue {
    background-color: $light;
}

.bg-light-purple {
    background-color: $flower;
}

.activity-intro {
    margin-top: 3rem;
    margin-bottom: 1rem;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.blue-theme {
    margin-bottom: 4rem;

    .activity-list-block {
        background-color: $dark;
        // color: $secondary;
    }

    .activity-links-download {

        p,
        em {
            color: $dark;

            Strong {
                color: $verydark;
            }
        }

        li:before {
            color: $dark;
        }
    }

    .activity-content-block-first {
        background-color: $light;
    }

    .activity-content-block-second {
        background-color: $dark;
    }
}

.purple-theme,
.pink-theme {
    margin-bottom: 4rem;

    .activity-list-block {
        background-color: $success;
        // color: $secondary;
    }

    .activity-links-download {
        background-color: $flower;

        p,
        em {
            color: $success;

            Strong {
                color: $primary;
            }
        }

        li:before {
            color: $success;
        }
    }

    .activity-content-block-first {
        background-color: $flower;
    }

    .activity-content-block-second {
        background-color: $primary;
    }
}

.activity-list-block {
    color: $secondary;
    // padding-left: 2rem;
    padding-bottom: 2rem;

    h3,
    h2 {
        color: $secondary;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        padding: 1.5rem 0;
        line-height: 1.5rem;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: -1.8rem;

        li {
            background-image: url(/static/images/check-solid.svg);
            background-size: 15px 15px;
            background-position: -0 0.3rem;
            background-repeat: no-repeat;
            padding: 0 0 0 1.8rem;
            // margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            line-height: 1.5rem;
        }
    }
}

.activity-content-block-second {
    color: $secondary;

    h3,
    h2 {
        color: $secondary;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        padding: 1.5rem 0;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: -1.8rem;

        li {
            background-image: url(/static/images/check-solid.svg);
            background-size: 15px 15px;
            background-position: -0 0.3rem;
            background-repeat: no-repeat;
            padding: 0 0 0 1.8rem;
            // margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            line-height: 1.5rem;
        }
    }
}

.left-row {
    padding-left: 3rem;

    @media only screen and (max-width: 768px) {
        padding-right: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        text-align: start;
        line-height: 1.5rem;
        padding: 0 !important;
        margin-bottom: 1.5rem;
    }
}

.right-row {
    padding-left: 3rem;
    padding-right: 3rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        text-align: start;
        line-height: 1.5rem;
        padding: 0 !important;
        margin-bottom: 1.5rem;
    }
}

.activity-links-download {
    // margin-bottom: 4rem;
    padding-bottom: 2rem;

    h2 {
        font-size: 1.5rem;
        text-align: center;
        padding: 1.5rem 0;
    }

    ul {
        list-style: none;
        list-style-type: none;
        // margin-left: -1.5rem;
        padding-left: 0;

        li {
            // padding: 0 0 0 2rem;
            margin-left: 1.5rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;

            &.download-link {
                &:before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f15b";
                    float: left;
                    margin-left: -1.5rem;
                }
            }

            &.link-extern {
                &:before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f0c1";
                    float: left;
                    margin-left: -1.5rem;
                }
            }

            &.link-intern {
                &:before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f061";
                    float: left;
                    margin-left: -1.5rem;
                }
            }

            &.video-link {
                &:before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f03d";
                    float: left;
                    margin-left: -1.5rem;
                }
            }

            &.link-video {
                &:before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f03d";
                    float: left;
                    margin-left: -1.5rem;
                }
            }
        }
    }

    p {
        line-height: 1.5rem;
        font-style: italic;

        strong {
            font-weight: 700;
            font-style: normal;
            display: block;
            margin-top: 1rem;
        }
    }
}

.activity-content-block-first {

    h3,
    h2 {
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        padding: 1.5rem 0;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: -1.8rem;

        li {
            background-image: url(/static/images/check-solid-purple.svg);
            background-size: 15px 15px;
            background-position: -0 0.3rem;
            background-repeat: no-repeat;
            padding: 0 0 0 1.8rem;
            // margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            line-height: 1.5rem;
        }
    }
}

.ts-1 {
    text-shadow: 0 0 5px #000;
}

.transparent {
    background-color: inherit !important;
}

/* theme colors for title in activity */
.title-blue {

    h1,
    h2,
    h3,
    h4 {
        color: $dark;
    }
}

.title-purple,
.title-pink {

    h1,
    h2,
    h3,
    h4 {
        color: $success;
    }
}

.riec_pillar_content {
    background-color: $info;
}

.riec_pillar_detail {
    color: var(--text-color-purple) !important;
}

.jv_site {
    &.riec_om_page {
        background-color: $info;

        .project-toggle {
            background-color: #fff;
            margin-bottom: 1rem;
        }
    }
}

.knelpunt-container {
    padding-bottom: 6rem;
}

.project-content {
    padding-top: 2rem;
    margin-bottom: 1rem;

    h1 {
        margin-bottom: 0;
    }
}

.placeholder-image-color {
    background-color: $seconds;
    display: flex;

    @media only screen and (max-width: 768px) {
        margin-bottom: 1rem;
    }
}

.purple-bg {
    background-color: $success;
}

.orange-bg {
    background-color: $danger;
}

.header-title-custom {
    font-size: 2.8rem;
}

.icon-logo-riec {
    svg {
        .cls-1 {
            fill: #e3a52b;
        }
    }
}

.activity-list-block li {
    background-image: url(/static/images/check-solid.svg) !important;
}

.activity-list-block .doelen-resultaten-container .doelen-resultaten-content .item-container .margins-listitems li {
    padding-top: 0 !important;
    margin-bottom: 1.5rem !important;
    line-height: 1.5rem !important;
}