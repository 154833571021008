#ObjectiveTabs {
    position: relative;
    margin-top: -30px;
    z-index: 1;

    .nav-item {
        padding-bottom: 30px;

        .nav-link {
            width: 130px;
            height: 130px;
            background-color: #e3a51a;
            color: #480e44;
            position: relative;
            background-repeat: no-repeat;
            background-position: center 10px;
            line-height: 16px;
            padding-bottom: 5px;
            font-size: 0.75rem;
            padding-left: 0;
            padding-right: 0;

            &:before {
                width: 0;
                height: 0;
                border-bottom: 30px solid #480e44;
                border-right: 10px solid transparent;
                content: "";
                @include absolute(0, -10px, inherit, inherit);
            }

            &:after {
                width: 0;
                height: 0;
                border-left: 66px solid transparent;
                border-right: 66px solid transparent;
                border-top: 28px solid #e3a51a;
                margin-left: auto;
                margin-right: auto;
                content: "";
                @include absolute(inherit, 0, -27px, 0);
                transition: color 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out;
            }
        }

        .nav-link {

            &:hover,
            &.active {
                background-color: $riec-purple;
                color: #fff;

                &:after {
                    border-top-color: $riec-purple;
                }
            }
        }

        &:nth-of-type(1) {
            .nav-link {
                background-image: url("/site/static/images/objective-tabs/tab-1.png");

                &.active,
                &:hover {
                    background-image: url("/site/static/images/objective-tabs/tab-1-hover.png");
                }
            }
        }

        &:nth-of-type(2) {
            .nav-link {
                background-image: url("/site/static/images/objective-tabs/tab-2.png");

                &.active,
                &:hover {
                    background-image: url("/site/static/images/objective-tabs/tab-2-hover.png");
                }
            }
        }

        &:nth-of-type(3) {
            .nav-link {
                background-image: url("/site/static/images/objective-tabs/tab-3.png");

                &.active,
                &:hover {
                    background-image: url("/site/static/images/objective-tabs/tab-3-hover.png");
                }
            }
        }

        &:nth-of-type(4) {
            .nav-link {
                background-image: url("/site/static/images/objective-tabs/tab-4.png");

                &.active,
                &:hover {
                    background-image: url("/site/static/images/objective-tabs/tab-4-hover.png");
                }
            }
        }

        &:nth-of-type(5) {
            .nav-link {
                background-image: url("/site/static/images/objective-tabs/tab-5.png");

                &.active,
                &:hover {
                    background-image: url("/site/static/images/objective-tabs/tab-5-hover.png");
                }
            }
        }
    }

    .GreyBox {
        background-color: #f5eff5;

        ul {
            list-style-type: none;

            li {}
        }
    }

    .arrow-right {
        color: $primary;
        font-size: 1.25rem;
        padding-left: 0.5rem;
    }

    .arrow-down {
        color: $info;
    }
}
